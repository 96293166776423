import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { getmemberinfo, getmemberorder } from '@/api/info'
import {
  getUserInfo
} from '@/api/info'

Vue.use(Vuex)
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: state => ({
    zhiboPassword: state.zhiboPassword, // 直播間密碼
    token: state.token, // 这个就是存入localStorage的值 vuex存储在内存，localstorage则以文件的方式存储在本地 可以自动将 Vuex的 store 数据本地持久化存储
    userInfo: state.userInfo,
    abc: state.abc,
    openid: state.openid,
    shopinfo: state.shopinfo,
    toPageUrl: state.toPageUrl,
    member: state.member,
    codeSUM: state.codeSUM,
    tabbarFalg: state.tabbarFalg,
    addressInfo: state.addressInfo,
    vConsoleShow: state.vConsoleShow,
    currPageUrl: state.currPageUrl,
    goodsInfo: state.goodsInfo,
    vuexLiveInfo: state.vuexLiveInfo,
    submitNumber: state.submitNumber,
    specs_id: state.specs_id,
    liveItem: state.liveItem,
    payInfo: state.payInfo,
    longDialogShow: state.longDialogShow,
    longDialogShowPC: state.longDialogShowPC,
    systemConfig: state.systemConfig,
    vuex_zhiBoDes: state.vuex_zhiBoDes,
    cartIds: state.cartIds, // 购物车下单ids
    shopId: state.shopId, // 购物车下单shop id
    bonusInfo: state.bonusInfo,
    businessBabbar: state.businessBabbar,
    zhiBoToken: state.zhiBoToken,
    recruitScrollY: state.recruitScrollY,
    liveRoomId: state.liveRoomId,
    searchItems: state.searchItems,
    appTheme: state.appTheme
  })
})
export default new Vuex.Store({
  state: {
    zhiboPassword: '', // 直播間密碼
    vConsoleShow: false || process.env.NODE_ENV === 'development', // vConsole显示
    terminalInfo: '',
    member: {},
    searchItems: [],
    token: null,
    specs_id: '',
    longDialogShow: false,
    longDialogShowPC: false,
    shopinfo: {},
    toPageUrl: '', // 記錄路由地址
    vuex_zhiBoDes: {},
    vuexLiveInfo: {}, // 支付直播间房间信息
    userInfo: {},
    codeSUM: 0, // code请求次数计数器
    abc: true,
    openid: '',
    tabbarFalg: false, // 是否显示 tabbar
    addressInfo: '',
    goodsInfo: {},
    submitNumber: '', // 详情页进入订单界面选择的数量
    payTypeId: '', // 一元秒杀id
    currPageUrl: '', // 为了处理token401 要保存路径
    liveItem: '', // 精品直播当前播放的视频
    payInfo: '', // 支付信息
    systemConfig: {},
    factoryItem: '', // 探厂当前项
    cartIds: '', // 购物车下单ids
    shopId: '', // 购物车下单shop id
    bonusInfo: '', // 优惠券信息
    wxSignUrl: '', // 保存路由地址
    businessBabbar: false, // 运营中心是否显示 tabbar
    zhiBoToken: null, // 直播间token
    recruitScrollY: 0, // 记录首页滑动位置
    liveRoomId: '', // 直播间ID
    appTheme: 'primary' // red：红色，blue：蓝色，orange：橙色，tide：国潮，primary：主要
  },
  mutations: { // 全局同步方法, 调用方法,this.$store.commit("xxx") // 设置状态
    // 专用于修改state中的数据，通过commit触发
    // setTerminalInfo (state, terminalInfo) {
    //   state.terminalInfo = terminalInfo
    //   localStorage.terminalInfo = terminalInfo // 同步存储token至localStorage
    // },
    $uStore(state, payload) {
      // 判断是否多层级调用，state中为对象存在的情况，诸如user.info.score = 1
      const nameArr = payload.name.split('.')
      // const saveKey = ''
      const len = nameArr.length
      if (nameArr.length >= 2) {
        let obj = state[nameArr[0]]
        for (let i = 1; i < len - 1; i++) {
          obj = obj[nameArr[i]]
        }
        obj[nameArr[len - 1]] = payload.value
      } else {
        // 单层级变量，在state就是一个普通变量的情况
        state[payload.name] = payload.value
      }
    },
    setabc(state, abc) {
      state.abc = abc
    },
    changeRecruitScrollY(state, recruitScrollY) {
      state.recruitScrollY = recruitScrollY
    },
    setVuexLiveInfo(state, vuexLiveInfo) {
      state.vuexLiveInfo = vuexLiveInfo
      localStorage.vuexLiveInfo = vuexLiveInfo
    },
    setvuex_zhiBoDes(state, vuex_zhiBoDes) {
      state.vuex_zhiBoDes = vuex_zhiBoDes
    },
    setZhiboPassword(state, Password) {
      state.zhiboPassword = Password
      localStorage.zhiboPassword = Password
    },
    setUserInfo(state, info) {
      state.userInfo = info
    },
    setToken(state, token) {
      state.token = token
      localStorage.token = token // 同步存储token至localStorage
      console.log(token)
    },
    setAppTheme(state, theme) {
      state.appTheme = theme
    },
    setZhiBoToken(state, token) {
      state.zhiBoToken = token
      localStorage.zhiBoToken = token // 同步存储token至localStorage
      console.log(token)
    },
    setTabbarShow(state, tabbarFalg) {
      state.tabbarFalg = tabbarFalg
    },
    setAddressInfo(state, info) {
      state.addressInfo = info
      localStorage.addressInfo = info
    },
    setGoodsInfo(state, info) {
      state.goodsInfo = info
    },
    setPayInfo(state, info) {
      state.payInfo = info
      localStorage.payInfo = info
    },
    setSubmitNumber(state, num) {
      state.submitNumber = num
      localStorage.submitNumber = num
    },
    setPayTypeId(state, id) { // 1元购项目id
      state.payTypeId = id
    },
    setLiveRoomId(state, id) { // 直播间ID
      state.liveRoomId = id
    },
    setVconsole(state) {
      state.vConsoleShow = true
    },
    setCurrPageUrl(state, pageUrl) {
      state.currPageUrl = pageUrl
    },
    setLiveItem(state, item) {
      state.liveItem = item
    },
    setFactoryItem(state, item) {
      state.factoryItem = item
    },
    setCartIds(state, item) {
      state.cartIds = item
    },
    setShopId(state, item) {
      state.shopId = item
    },
    setBonusInfo(state, item) {
      state.bonusInfo = item
    },
    setWechatSignUrl(state, wxSignUrl) {
      // 关键点
      // IOS仅记录第一次进入页面时的URL
      // IOS微信切换路由实际URL不变，只能使用第一进入页面的URL进行签名
      state.wxSignUrl = wxSignUrl
    }
  },
  getter: { // Vuex属性计算,在视图里面当变量使用 // 获取状态
    // Vuex中的计算属性，相当于vue中的computed,依赖于state状态值，
    // 状态值一旦改变，getter会重新计算，也就是说，
    // 当一个数据依赖于另一个数据发生变化时，就要使用getter
    // 判断是否有token,如果没有重新赋值，返回给state的token

    getToken(state) {
      if (!state.token) {
        state.token = localStorage.getItem('token')
      }
      return state.token
    },
    getZhiBoToken(state) {
      if (!state.token) {
        state.zhiBoToken = localStorage.getItem('zhiBoToken')
      }
      return state.token
    },
    getAppTheme(state) {
      return state.appTheme
    },
    recruitScrollY(state) {
      return state.recruitScrollY
    },

    // getGoodsInfo() {
    //   if (!state.goodsInfo) {
    //     state.goodsInfo = localStorage.getItem('goodsInfo')
    //   }
    //   return state.goodsInfo
    // },
    // getPayInfo() {
    //   if (!state.payInfo) {
    //     state.payInfo = localStorage.getItem('payInfo')
    //   }
    //   return state.payInfo
    // },
    // getSubmitNumber() {
    //   if (!state.submitNumber) {
    //     state.submitNumber = localStorage.getItem('submitNumber')
    //   }
    //   return state.submitNumber
    // },
    getAddressInfo(state) {
      if (!state.addressInfo) {
        state.addressInfo = localStorage.getItem('addressInfo')
      }
      return state.addressInfo
    },
    getLiveItem(state) {
      if (!state.liveItem) {
        state.liveItem = localStorage.getItem('liveItem')
      }
      return state.liveItem
    },
    getCartIds(state) {
      if (!state.cartIds) {
        state.cartIds = localStorage.getItem('cartIds')
      }
      return state.cartIds
    },
    getShopId(state) {
      if (!state.shopId) {
        state.shopId = localStorage.getItem('shopId')
      }
      return state.shopId
    },
    getWechatSignUrl(state) {
      return state.wxSignUrl
    },
    getRoomId(state) {
      if (!state.liveRoomId) {
        state.liveRoomId = localStorage.getItem('liveRoomId')
      }
    }
  },
  actions: { // 异步方法 调用方法,this.$store.dispatch("xxx") // 异步提交mutations
    // 可以处理异步，通过dispatch触发，不能直接修改state，
    // 首先在组件中通过dispatch触发action，然后在action函数内部commit触发mutation，通过mutation修改state状态值
    setMemberInfo({ commit }) {
      getmemberinfo().then(res => {
        if (res.code == 200) {
          commit('$uStore', {
            name: 'member', value: res.data.member
          })
          // that.member_data = res.data.member_data.map(item => {
          //   item.active = 1
          //   return item
          // })
          // this.couponList = that.member_data[0]
        } else {
          this.$toast(res.message)
        }
      })
    },
    setuserInfo({ commit }) {
      getUserInfo().then(res => {
        if (res.code == 200) {
          const info = res.data
          // console.log('info')
          commit('setUserInfo', info)
          // this.getCookie()
        }
      })
    }
  },
  modules: { // 模块化管理 把状态管理模块化，各自的组件构成各自的模块
  },
  plugins: [vuexLocal.plugin]
})
