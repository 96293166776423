const FunMemberRouter = [

    {
      path: '/fun-demand-home',
      name: 'home',
      component: () => import(/* webpackChunkName: "about" */ '../views/funMember/demand-home'),
      meta: {
          title: '好玩会员',  // 列表
        }
    },
    {
      path: '/fun-demand-home-i',
      name: 'home-i',
      component: () => import(/* webpackChunkName: "about" */ '../views/funMember/demand-home-i'),
      meta: {
          title: '好玩会员',  // 列表
        }
    },
    {
      path: '/fun-demand-home-lm',
      name: 'home-lm',
      component: () => import(/* webpackChunkName: "about" */ '../views/funMember/demand-home-lm'),
      meta: {
          title: '好玩会员',  // 列表
        }
    },
    {
      path: '/fun-demand-home-p',
      name: 'home-p',
      component: () => import(/* webpackChunkName: "about" */ '../views/funMember/demand-home-p'),
      meta: {
          title: '好玩会员',  // 列表
        }
    },
    {
        path: '/fun-demand-index',
        name: 'demo',
        component: () => import(/* webpackChunkName: "about" */ '../views/funMember/demand-index'),
        meta: {
            title: '好玩会员',  // 列表
          }
    },
    {
      path: '/fun-demand-apply',
      name: 'apply',
      component: () => import(/* webpackChunkName: "about" */ '../views/funMember/demand-apply'),
      meta: {
          title: '好玩会员',  // 列表
        }
    },
    {
      path: '/fun-demand-member',
      name: 'member',
      component: () => import(/* webpackChunkName: "about" */ '../views/funMember/demand-member'),
      meta: {
          title: '好玩会员',
        }
    },
]

export default FunMemberRouter
