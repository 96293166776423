const IntegralShopRouter = [
    {
        path: '/integral',
        name: 'integral',
        component: () => import(/* webpackChunkName: "about" */ '../views/integral/integral'),
        meta: {
            title: '积分中心'
        }
    },
    {
        path: '/integralGoods',
        name: 'integralGoods',
        component: () => import(/* webpackChunkName: "about" */ '../views/integral/integralGoods'),
        meta: {
          title: '积分商品',
          desc:'分享你一个好物，一起来领代金券畅购吧'
        }
      },
      {
        path: '/integralLog',
        name: 'integralLog',
        component: () => import(/* webpackChunkName: "about" */ '../views/integral/integralLog'),
        meta: {
          title: '积分明细'
        }
      },
      {
        path: '/integral-rule',
        name: 'integralRule',
        component: () => import(/* webpackChunkName: "about" */ '../views/integral/integralRule'),
        meta: {
          title: '积分规则'
        }
      },

]

export default IntegralShopRouter