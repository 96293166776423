const DEFAULT_API = {
  getHomeData: {
    method: 'get',
    url: '/api/basic/home'
  },
  login: {
    method: 'get',
    url: '/api/wechat/mp-login'
  },
  register: {
    method: 'post',
    url: 'member/passport/signup'
  },
  codeNumber: {
    method: 'post',
    url: 'common/sms/send'
  },
  getInfo: {
    method: 'get',
    url: '/api/user'
  },
  getCategoryList: {
    method: 'get',
    url: '/api/goods/category'
  },
  getGoodsList: {
    method: 'get',
    url: '/api/goods'
  },
  getGoodsListBusiness: { // 商户列表
    method: 'get',
    url: '/api/user/shop/goods'
  },
  getCommentList: {
    method: 'get',
    url: '/api/basic/comments'
  },
  getGoodsListGift: { // 手信分类（一级）
    method: 'get',
    url: '/api/goods/gift-category'
  },
  getGoodsCategories: { // 分类（一级）
    method: 'get',
    url: '/api/goods/categories'
  },
  getGoodsCategoriesBusiness: { // 商户分类（一级）
    method: 'get',
    url: '/api/user/shop/goods/category'
  },
  getAdv: {
    method: 'get',
    url: '/api/basic/adv'
  },
  getGoodDes: {
    method: 'get',
    url: `/api/goods/`
  },
  getOnePay: {
    method: 'get',
    url: '/api/goods/onepay'
  },
  getCheckoutPay: {
    method: 'get',
    url: '/api/cart/checkout'
  },
  companyReport: {
    method: 'post',
    url: '/api/company-report'
  },
  // login: {
  //   method: 'post',
  //   url: 'application/passport/auth'
  // },
  // login: _api_root + "passport/login",
  // on_login: _api_root + "passport/on-login"
  editContact: {
    method: 'put',
    url: 'contact/edit'
  },
  delContact: {
    method: 'delete',
    url: '/contact'
  },
  getIndexArr: { // 首页数据
    method: 'get',
    url: '/api/basic/home-v2'
  },
  getHaituanIndexArr: { // 海团首页数据
    method: 'get',
    url: '/api/basic/home-haituan'
  }
  // /api/company-report
}
export default DEFAULT_API
