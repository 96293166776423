export function GetParam(path = window.location.href) {
  const query = {}
  const queryList = path.split('?')
  // 获取query
  if (queryList[1]) {
    const str = queryList[1].replace('#/', '')
    const queryDataList = str.split('&')
    queryDataList.map((item) => {
      const queryData = item.split('=')
      query[queryData[0]] = queryData[1]
    })
    return query
  } else {
    return false
  }
}

export const buildUrl = (path) => {
  const query = {}
  const queryList = path.split('?')
  // 获取query
  if (queryList[1]) {
    const queryDataList = queryList[1].split('&')
    // const queryDataList = queryList[1].split('&')

    queryDataList.map((item) => {
      const queryData = item.split('=')
      if (queryData[0] != 'code' || queryData[0] != 'state') {
        query[queryData[0]] = queryData[1]
      }
    })
  }
  let str = ''
  Object.keys(query).forEach(key => {
    str += `&${key}=${encodeURIComponent(query[key])}`
  })
  return `${queryList[0]}?${str.slice(1)}`
}
