import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible'
import dataV from '@jiaminghi/data-view'
import Color from '@/style/color.scss' // 引入主scss变量
import hls from 'videojs-contrib-hls'
import Http from './service/request.js'
import { wexinShare,hideShare, getCode, isWechatOrAlipayBrowser, pcNextHanlde, isIos, mainWebsite } from '@/utils/wechatJsSdk'
import VerifyIdentity from '@/utils/VerifyIdentity.js'
import { GetParam, buildUrl } from '@/utils/getParam.js'
import { getGuestId, getLogin,loginAsGuest } from '@/api/info'
import { setStore } from '@/utils/setStore'
import navigateBack from '@/utils/navigate'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import 'dayjs/locale/zh-cn'
moment.locale('zh-cn')
import { Lazyload } from 'vant'
import moment from 'dayjs'
moment.extend(isSameOrBefore)
moment.extend(isSameOrAfter)
moment.extend(weekOfYear)

import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
Vue.use(dataV)
Vue.prototype.$Http = Http // 把Http挂载到vue 实例上  全局都可以用
Vue.prototype.$setvuex = setStore // 把Http挂载到vue 实例上  全局都可以用
Vue.use(hls) // 要播放hls流,
Vue.prototype.$imgurl = process.env.VUE_APP_IMG_URL
Vue.prototype.$Color = Color // 将scss变量挂载到全局
Vue.prototype.$VerifyIdentity = VerifyIdentity // 将scss变量挂载到全局
Vue.prototype.$navigateBack = navigateBack // 将scss变量挂载到全局
Vue.prototype.$moment = moment
Vue.prototype.$wexinShare = wexinShare
Vue.prototype.$hideShare = hideShare

Vue.config.productionTip = false
Vue.use(Vant)
Vue.use(Lazyload)
Vue.config.ignoredElements = ['wx-open-subscribe', 'wx-open-launch-weapp']

router.afterEach((to, from) => {
  // sendToAnalytics(to.fullPath)
})
router.beforeEach(async(to, from, next) => {
  if(mainWebsite(to, from, next) === true) {
    return
  }
  pcNextHanlde(to, from, next)

  // 默认标题
  document.title = process.env.VUE_APP_NAME
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 在main.js里判断是否是ios和vuex保存的这个url是否为空，为空则存入当前url：
  if (isIos() && store.state.wxSignUrl == '') {
    store.commit('setWechatSignUrl', process.env.VUE_APP_BASE_Url + to.fullPath)
  }

  const token = store.state.token

  if(to.query.next === 'pc' || globalGetQueryParam('next') === 'pc'){
    next()
    return
  }
  if (isWechatOrAlipayBrowser() && to.path == '/goodDetails') {
    // 从首页，商品列表也，分类页，收藏夹，商家详情点击进入商品详
    // const routerList = []
    if (from.name !== null) {
      // 查询是是否登錄
      if (store.state.userInfo?.unionid) {
        next()
      } else {
        // 記錄要去的路由
        setStore('toPageUrl', to.fullPath)
        setStore('longDialogShow', true)
        return
      }
    }
  }
  // 已登录
  if(token) {
    next()
    return
  }else if(to.path == '/settoken'){
    next()
    return
  }
  // 未登录且不是微信和支付宝浏览器（PC） || 开发地址
  else if (!token && !(isWechatOrAlipayBrowser())) {
    setStore('token', '');
    setStore('userInfo', {});

    const guestId = to.query.guestId;
    const guestLogin = to.query.guestLogin;

    const handleGuestLogin = (credentials) => {
      getGuestId(credentials).then((res1) => {
        if (res1.code === 200) {
          loginAsGuest({ guestId: res1.data }).then((res2) => {
            if (res2.code === 200) {
              setStore("token", res2.data.token);
              setStore("userInfo", res2.data.user);
              this.$toast({
                message: "登录成功",
                duration: 3000,
                onClose: () => location.reload(),
              });
            }
          });
        } else {
          this.$dialog.alert({
            message: '登录失败',
            confirmButtonText: '知道了'
          });
        }
      });
    };

    if (guestLogin) {
      handleGuestLogin({ password: guestLogin });
    } else if (guestId) {
      handleGuestLogin({ guestId: guestId });
    } else {
      setStore('longDialogShowPC', true);
    }

    next();
    return;
  }


  // 未登录
  const href = buildUrl(window.location.origin + to.fullPath)
  const query = GetParam()
  if (!query.code) {
    getCode(href, query.scope)
  } else {
    const data = {
      code: query.code,
      source: 'ZHQQ',
      scope: query.state,
      access: query.access ? query.access : 'SELF'  // 自己平台(默认，可不传)：SELF，中山通：ZST
    }

    // 下面是我根据我实际业务场景编写的，主要是根据code获取个人信息保存。授权登录走到这一步基本就做完了
    await getLogin(data).then(res => {
      setStore('codeSUM', store.state.codeSUM + 1)
      if (res.code == 200) {
        setStore('token', res.data.token) // 保存token
        if (res.data.base_newuser && to.name != 'Home' && to.name != 'shop') {
          setStore('longDialogShow', true)
          setStore('toPageUrl', to.fullPath)
        } else {
          setStore('setTabbarShow', true)
          setStore('userInfo', res.data.user)
          setStore('openid', res.data.wechat.id)
          setStore('longDialogShow', false)
          next()
        }
      } else if (res.code == 401) { // // 登录过期
        setStore('token', '')
        setStore('userInfo', {})
        getCode(href, query.scope)
        // 如果请求失败继续走重定向去获取code这一步
      } else {
        // 请求 获取请求计数器
        if (store.state.codeSUM < 5) {
          getCode(href, query.scope)
        } else {
          setStore('codeSUM', 0)
        }
        // 如果请求失败继续走重定向去获取code这一步
      }
    })
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
