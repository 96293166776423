import axios from 'axios'
import service from './api.js'
import { Toast } from 'vant'
import store from '../store/index'
import QS from 'qs'

let baseUrl = process.env.VUE_APP_API_URL
// service循环输出不同的请求方法
const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    'content-type': 'application/x-www-form-urlencoded'
  },
  timeout: 15000
})

const Http = {} // 包裹请求方法的容器
// 请求格式、参数的统一
for (const i in service) {
  const api = service[i] // url method
  Http[i] = async function(
    // 请求参数 get：url，put，post，patch（data），delete：url
    params,
    isFormData = false, // 是否是formdata
    config = {} // 配置参数
  ) {
    // 判断是否是formData， 如果是 转成form-data的格式
    let newParams = {}
    // content-type 是否是form-data的判断
    if (params && isFormData) {
      newParams = new FormData()
      for (const j in params) {
        newParams.append(j, params[j])
      }
    } else {
      newParams = params
    }

    // 不同请求的判断
    let response = {} // 请求的返回值
    if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
      try {
        // qs.stringify()将对象 序列化成URL的形式，以&进行拼接
        response = await instance[api.method](api.url, QS.stringify(newParams), config)
      } catch (err) {
        response = err
      }
    } else if (api.method === 'delete' || api.method === 'get') {
      config.params = newParams
      try {
        response = await instance[api.method](api.url, config)
      } catch (err) {
        response = err
      }
    }
    return response
  }
}

// 拦截器的添加
// 请求拦截器
instance.interceptors.request.use(config => {
  const token = store.state.token
  if (token != null) {
    token && (typeof token !='boolean') && (config.headers.Authorization = token)
  }

  return config
}, err => {
  // 请求错误
  Toast.clear()
  console.log(err)
  return Promise.reject(err)
})

// 响应拦截器
instance.interceptors.response.use(res => {
  // 响应成功
  Toast.clear()
  return res.data
}, err => {
  // 响应失败
  console.log(err)
  Toast.clear()
  return Promise.reject(err.response.data) // 返回接口返回的错误信息
})

export default Http