import Vue from 'vue'
import VueRouter from 'vue-router'
import OutsideRouter from '@/router/outside'
import IntegralShopRouter from '@/router/integralShop'
import FormBodyRouter from '@/router/formBody'
import FunMemberRouter from '@/router/funMember'
import ToolsRouter from '@/router/tools'
import HaiTuanRouter from '@/router/haituan'

Vue.use(VueRouter)

/**
 * keepAlive: false     // 此组件不需要被缓存
 * isBack: false        // 用于判断上一个页面是哪个
 * requireLogin: true   // 判断页面是否需要登录
 */
const routes = [
  {
    path: '/adPage',
    name: 'adPage',
    component: () => import(/* webpackChunkName: "about" */ '../views/ad'),
    meta: {
      title: '精品中山', // 广告页面
      keepAlive: true,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/',
    name: 'Home',
    //component: Home, // 首页
	component: () => import(/* webpackChunkName: "about" */ '../views/HomeSec'),
    meta: {
      title: '精品中山',
      requireLogin: true
    }
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/User'),
    meta: {
      title: '个人中心',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/order/index'),
    meta: {
      title: '订单',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/orderDes',
    name: 'OrderDes',
    component: () => import('../views/order/des'),
    meta: {
      title: '订单详情',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/orderComment',
    name: 'orderComment',
    component: () => import('../views/order/comment'),
    meta: {
      title: '评价',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/orderSalesReturn',
    name: 'orderSalesReturn',
    component: () => import('../views/order/orderSalesReturn'),
    meta: {
      title: '申请退货',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/orderparcel',
    name: 'orderparcel',
    component: () => import('../views/order/parcel'),
    meta: {
      title: '物流信息',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/login'),
    meta: {
      title: '登录',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "about" */ '../views/Service'),
    meta: {
      title: '智能客服',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cart'),
    meta: {
      title: '购物车',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/factoryList',
    name: 'factoryList',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/factoryList'),
    meta: {
      title: '精品探厂',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/factoryDes',
    name: 'factoryDes',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/factoryDes'),
    meta: {
      title: '精品探厂',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/factoryDes2',
    name: 'factoryDes2',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/factoryDes2'),
    meta: {
      title: '精品探厂',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/zhiBoList',
    name: 'zhiBoList',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/zhiBoList'),
    meta: {
      title: '直播精品',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/zhoboTest', // 测试页面
    name: 'zhoboTest',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/zhoboTest'),
    meta: {
      title: '直播',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/materialList',
    name: 'materialList',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/materialList'),
    meta: {
      title: '直播精品', // 视频素材模块
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/zhiBoDes',
    name: 'zhiBoDes',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/zhiBoDes'),
    meta: {
      title: '直播间详情',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      oneWechatShare: true, // 避免多次分享
    }
  },
  {
    path: '/roomPassword',
    name: 'roomPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/roomPassword'),
    meta: {
      title: '直播间密码验证',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/payComeBack',
    name: 'payComeBack', // 用于直播间礼物支付、红包支付成功后获取直播间id
    component: () => import(/* webpackChunkName: "about" */ '../views/home/payComeBack'),
    meta: {
      title: '支付成功',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/liveDes2',
    name: 'liveDes2', // 直播精品视频重播详情
    component: () => import(/* webpackChunkName: "about" */ '../views/home/liveDes2'),
    meta: {
      title: '直播精品详情',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/activityTheme/:type', // 用在中山手信、一些活动商品界面 根据不同的类型获取不同的数据
    name: 'ActivityTheme',
    component: () => import(/* webpackChunkName: "activityTheme" */ '../views/goods/activityTheme'),
    meta: {
      title: '精品中山', //
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      oneWechatShare: true, // 避免多次分享
    }
  },
  {
    path: '/goodDetails',
    name: 'GoodDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/GoodDetails'),
    meta: {
      title: '商品详情',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      oneWechatShare:true, // 避免多次分享
    }
  },
  {
    path: '/wholesaleDetails',
    name: 'WholesaleDetails',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/wholesaleDetails'),
    meta: {
      title: '景区集采或联营',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      oneWechatShare: true, // 避免多次分享
    }
  },
  {
    path: '/shopDes',
    name: 'ShopDes',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/shopDes'),
    meta: {
      title: '企业详情',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/orderPay',
    name: 'orderPay',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/orderPay'),
    meta: {
      title: '订单结算',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/cartPay',
    name: 'cartPay',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/cartPay'),
    meta: {
      title: '订单结算',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true,
    }
  },
  {
    path: '/orderPayDelivery',
    name: 'orderPayDelivery',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/orderPayDelivery'),
    meta: {
      title: '订单结算（自提）',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true,
    }
  },
  {
    path: '/submitOrder',
    name: 'submitOrder',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/submitOrder'),
    meta: {
      title: '支付订单',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true,
    }
  },
  {
    path: '/paySucceed',
    name: 'paySucceed',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/paySucceed'),
    meta: {
      title: '支付完成',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true,
    }
  },
  {
    path: '/goodClassify',
    name: 'GoodClassify',
    component: () => import('../views/goods/classify'),
    meta: {
      title: '中山精品', // 分类
      requireLogin: true,
      keepAlive: false,
      isBack: false,
      oneWechatShare:true, // 避免多次分享
    }
  },
  {
    path: '/oneYuan',
    name: 'oneYuan',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/oneYuan'),
    meta: {
      title: '1元精品',
      requireLogin: true,
      keepAlive: false,
      isBack: false,
      oneWechatShare:true, // 避免多次分享
      desc:'比拼多多最低还要低'
    }
  },
  {
    path: '/searchList',
    name: 'searchList',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/searchList'),
    meta: {
      title: '搜索列表',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      oneWechatShare:true, // 避免多次分享
    }
  },
  {
    path: '/goodList',
    name: 'GoodList',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/list'),
    meta: {
      title: '商品列表',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/couponDes',
    name: 'couponDes',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/couponDes'),
    meta: {
      title: '消费券',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      desc:'好券分享等你领~',
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/traceGoodsSpecification/:id', // 查询商品编码，查询结果为官方授权的产品
    name: 'traceGoodsSpecification',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/goodsSpecification'),
    meta: {
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      title: '商品详情'
    }
  },
  {
    path: '/collectList',
    name: 'collectList',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/collect'),
    meta: {
      title: '我的收藏',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/recharge'),
    meta: {
      title: '充值',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/wallet'),
    meta: {
      title: '会员钱包',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/xieyi',
    name: 'xieyi',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/xieyi'),
    meta: {
      title: '协议',
      keepAlive: false,
      isBack: false,
      requireLogin: false
    }
  },
  {
    path: '/xieYiList',
    name: 'xieYiList',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/xieYiList'),
    meta: {
      title: '协议列表',
      keepAlive: false,
      isBack: false,
      requireLogin: false
    }
  },
  {
    path: '/xieYiDes',
    name: 'xieYiDes',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/xieYiDes'),
    meta: {
      title: '用户协议',
      keepAlive: false,
      isBack: false,
      requireLogin: false
    }
  },
  {
    path: '/shop/:id',
    name: 'shop',
    component: () => import('../views/user/shop'),
    meta: {
      title: '买单',
      keepAlive: false,
      isBack: false,
      requireLogin: false,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/shoper/order/:id/:code',
    name: 'shoperOrder',
    component: () => import('../views/shoper/order'),
    meta: {
      title: '自提核销',
      keepAlive: false,
      isBack: false,
      requireLogin: false
    }
  },
  {
    path: '/billList',
    name: 'billList',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/billList'),
    meta: {
      title: '支付账单',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/agreement'),
    meta: {
      title: '平台协议',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/bindingCard',
    name: 'bindingCard',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/burse/bindingCard'),
    meta: {
      title: '绑定银行卡',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/myCard',
    name: 'myCard', // 我的银行卡
    component: () => import(/* webpackChunkName: "about" */ '../views/user/burse/myCard'),
    meta: {
      title: '我的银行卡', // 我的银行卡
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/openBurse',
    name: 'openBurse', // 开通钱包
    component: () => import(/* webpackChunkName: "about" */ '../views/user/burse/openBurse'),
    meta: {
      title: '开通钱包',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/withdrawDeposit',
    name: 'withdrawDeposit', // 提现界面
    component: () => import(/* webpackChunkName: "about" */ '../views/user/burse/withdrawDeposit'),
    meta: {
      title: '提现',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/contactUs',
    name: 'contactUs',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/contactUs'),
    meta: {
      title: '联系我们',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/commentlist',
    name: 'commentlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/commentlist'),
    meta: {
      title: '买家说',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/classifyList',
    name: 'classifyList',
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/classifyList'),
    meta: {
      title: '商品列表',
      keepAlive: false,
      isBack: false,
      requireLogin: true
    }
  },
  {
    path: '/addressList',
    name: 'addressList',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/addressList'),
    meta: {
      title: '地址列表',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/selectAddress',
    name: 'selectAddress', // 废弃
    component: () => import(/* webpackChunkName: "about" */ '../views/goods/selectAddress'),
    meta: {
      title: '地址列表',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/addressEdit',
    name: 'addressEdit',
    component: () => import('../views/user/addressEdit'),
    meta: {
      title: '编辑地址',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },

  {
    path: '/discountCoupon',
    name: 'discountCoupon', // 我的优惠券界面
    component: () => import('../views/goods/discountCoupon'),
    meta: {
      title: '我的优惠券',
      keepAlive: false,
      isBack: false,
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/indexBusiness',
    name: 'indexBusiness', // 商家运营中心
    component: () => import('../views/merchant/indexBusiness'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '商家运营中心',
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/digitalMarketing',
    name: 'digitalMarketing', // / 数字营销界面
    component: () => import('../views/merchant/digitalMarketing'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '数字营销',
      requireLogin: true
    }
  },
  {
    path: '/marketingOrderDes',
    name: 'marketingOrderDes', // 数字营销订单详情界面
    component: () => import('../views/merchant/marketingOrderDes'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '数字营销订单详情',
      requireLogin: true
    }
  },
  {
    path: '/businessGoodsList', // 商家中心商品管理列表
    name: 'businessGoodsList',
    component: () => import('../views/merchant/businessGoodsList'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '商家中心商品列表',
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/businessGoodsAdd', // 商家中心商品新增和编辑
    name: 'businessGoodsAdd',
    component: () => import('../views/merchant/goodsAdd'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '商家中心商品',
      requireLogin: true,
      wechatShare: true, // 是否分享
    }
  },
  {
    path: '/signIn',
    name: 'signIn',
    component: () => import('../views/user/member/signIn'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '签到有礼',
      requireLogin: true
    }
  },
  {
    path: '/memberRegister',
    name: 'memberRegister',
    component: () => import('../views/user/member/memberRegister'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '会员注册',
      requireLogin: true
    }
  },
  {
    path: '/scanBusiness',
    name: 'scanBusiness',
    component: () => import('../views/merchant/scan'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '扫一扫',
      requireLogin: true
    }
  },
  {
    path: '/Buy',
    name: 'Buy',
    component: () => import('@/views/goods/Buy/index'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '向商戶付款',
      requireLogin: true
    }
  },
  {
    path: '/BuyOrder',
    name: 'BuyOrder',
    component: () => import('@/views/goods/Buy/order'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '付款记录',
      requireLogin: false
    }
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/user/Vip'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '付费会员',
      requireLogin: false
    }
  },
  {
    path: '/distributorlist',
    name: 'distributorlist',
    component: () => import('@/views/user/distributor/distributorlist'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '经销商列表',
      requireLogin: false
    }
  },
  {
    path: '/distributoradd',
    name: 'distributoradd',
    component: () => import('@/views/user/distributor/distributoradd'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '经销商列表',
      requireLogin: false
    }
  },
  {
    path: '/distributorDetail',
    name: 'distributorDetail',
    component: () => import('@/views/user/distributor/distributorDetail'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '经销商详情',
      requireLogin: false
    }
  },
  {
    path: '/distributorEdit',
    name: 'distributorEdit',
    component: () => import('@/views/user/distributor/distributorEdit'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '编辑经销商',
      requireLogin: false
    }
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('@/views/user/member/record'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '佣金收益',
      requireLogin: false
    }
  },
  {
    path: '/settoken',
    name: 'settoken',
    component: () => import('../views/settoken'),
    meta: {
      keepAlive: false,
      isBack: false,
      title: '更新token', // 本地开发时防止跳转微信登录时使用
      requireLogin: true
    }
  },
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import('@/views/iframe/iframe'),
    meta: {
      keepAlive: false, // 此组件不需要被缓存
      isBack: false, // 用于判断上一个页面是哪个
      requireLogin: false
    }
  },
  {
    path: '/meta-index',
    name: 'meteIndex',
    component: () => import('@/views/meta/index'),
    meta: {
      title: '中山元宇宙', // 本地开发时防止跳转微信登录时使用
    }
  },
  ...OutsideRouter,          // 外部项目
  ...IntegralShopRouter,     // 积分商城
  ...FormBodyRouter,         // 表单
  ...FunMemberRouter,        // 好玩会员
  ...ToolsRouter,            // 工具
  ...HaiTuanRouter,          // 海团购
]

// 解决 Failed to resolve async component default: TypeError: Cannot read property '1' of undefined
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const router = new VueRouter({
  routes,
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  /**
   * 点击浏览器的“前进/后退”，或者切换导航的时候触发
   * @param {*} to 要进入的目标路由对象，到哪里去
   * @param {*} from 离开的路由对象, 哪里来
   * @param {*} savePosition 记录滚动条的坐标，点击前进/后退的时候记录值{x:?,y:?}
   * @returns 解决页面从列表页跳转到详情页返回,初始在原来位置
   */
  scrollBehavior(to, from, savePosition) {
    if (savePosition) {
      return savePosition //
    }
    // 解决页面跳转后页面高度和前一个页面高度一样
    return { x: 0, y: 0 }
  }
})

export default router
