const OutsideRouter = [
      {
        path: '/consumerRegister',
        name: 'consumerRegister',
        component: () => import('../views/consumer/register'),
        meta: {
          requireLogin: true,
          title: '中山通会卡注册',
          keepAlive: false,
          isBack: false
        }
      },
      {
        path: '/consumerLogin',
        name: 'consumerLogin',
        component: () => import('../views/consumer/login'),
        meta: {
          title: '中山通会卡登录',
          requireLogin: true,
          keepAlive: false,
          isBack: false
        }
      },
      {
        path: '/imaglist',
        name: 'imaglist',
        component: () => import(/* webpackChunkName: "about" */ '../views/imaglist/imaglist'),
        meta: {
          title: '2023中国智能照明产业发展论坛',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      
]

export default OutsideRouter