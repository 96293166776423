const wx = require('jweixin-module')
const ACCESS_ZST = 'ZST'  // 中山通（引流来源入口）

import { getWechat } from '@/api/zhibo'
import { buildUrl } from '@/utils/getParam.js'
import store from '@/store'

export const wexinShare = (shareData) => {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  let pageUrl = location.href
  if (isiOS) { // ios
    // ios系统就拼接随机字符串
    pageUrl = store.state.wxSignUrl
  }

  const http = window.location.protocol + '//'
  const url = window.location.hostname // 获取当前访问（域名）
  const pic = http + url + '/logo.jpg' // 'https://jpzs-zst.itos.cn/logo.jpg'
  shareData.link = shareData.link || pageUrl
  shareData.title = shareData.title || '精品中山'
  shareData.imgUrl = shareData.imgUrl || pic

  getWechat({
    liveUrl: pageUrl // encodeURIComponent(location.href.split('#')[0]) //  pageUrl
  }).then(res => {
    var weChat = res.data
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
      appId: weChat.appId, // 必填，公众号的唯一标识
      timestamp: weChat.timestamp, // 必填，生成签名的时间戳
      nonceStr: weChat.nonceStr, // 必填，生成签名的随机串
      signature: weChat.signature, // 必填，签名
      jsApiList: ['checkJsApi', 'onMenuShareTimeline', 'onMenuShareAppMessage', 'updateAppMessageShareData', 'updateTimelineShareData','showMenuItems'],
      openTagList: ['onMenuShareTimeline', 'onMenuShareAppMessage', 'updateAppMessageShareData', 'updateTimelineShareData','showMenuItems'] // 跳转小程序时必填
    })
    wx.checkJsApi({
      jsApiList: ['chooseImage', 'updateAppMessageShareData','showMenuItems'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
      success: function(res) {
      // 以键值对的形式返回，可用的api值true，不可用为false
      // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
      }
    })
    wx.ready(function() {
      wx.showMenuItems({
        menuList: ["menuItem:share:appMessage", "menuItem:share:timeline", "menuItem:share:qq", "menuItem:share:weiboApp", "menuItem:share:facebook", "menuItem:share:QZone","menuItem:favorite", "menuItem:openWithQQBrowser","menuItem:openWithSafari", "menuItem:refresh","menuItem:copyUrl","menuItem:setFont"],  // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        success: function (res) {
          // 隐藏菜单成功
          console.log('显示成功',res)
        },
        fail: function (res) {
          // 隐藏菜单失败
          console.log('显示失败',res)
        }
      });
      //分享到朋友圈”及“分享到QQ空间”
      wx.updateTimelineShareData({
        ...shareData,
        success: function(res) {
        // 设置成功
        }
      })

      // “分享给朋友”及“分享到QQ”
      wx.updateAppMessageShareData({
        ...shareData,
        success: function(res) {
        }
      })
      // 旧版本
      wx.onMenuShareAppMessage({
        ...shareData,
        success: function(e) {
          // 用户点击了分享后执行的回调函数
        }
      })
    })
    wx.error(function(res) {
    // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    })
  })
}


export const hideShare = () => {
  const u = navigator.userAgent
  const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  let pageUrl = location.href
  if (isiOS) { // ios
    // ios系统就拼接随机字符串
    pageUrl = store.state.wxSignUrl
  }
  getWechat({
    liveUrl: pageUrl, // encodeURIComponent(location.href.split('#')[0]) //  pageUrl
    jsApiList: ['hideAllNonBaseMenuItem','hideAllNonBaseMenuItem']
  }).then(res => {
    if (res.code!== 200) {
      return
    }
    var weChat = res.data
    console.log('hideShare',weChat)
    wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
      appId: weChat.appId, // 必填，公众号的唯一标识
      timestamp: weChat.timestamp, // 必填，生成签名的时间戳
      nonceStr: weChat.nonceStr, // 必填，生成签名的随机串
      signature: weChat.signature, // 必填，签名
      jsApiList: weChat.jsApiList,
    })
    wx.ready(function () {
      wx.hideAllNonBaseMenuItem()
      wx.showMenuItems({
        menuList: ["menuItem:favorite", "menuItem:openWithQQBrowser","menuItem:openWithSafari", "menuItem:refresh","menuItem:copyUrl","menuItem:setFont"],  // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        success: function (res) {
          // 隐藏菜单成功
          console.log('显示成功',res)
        },
        fail: function (res) {
          // 隐藏菜单失败
          console.log('显示失败',res)
        }
      });
    })
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('报错',res)
    })
  })


  // wx.hideOptionMenu()
}

/**
 * 获取code
 *
 * @param { string} fullPath  回调路径 返回的
 * @param {string} scope  请求的类型  snsapi_userinfo  获取全部信息 |snsapi_base 只获取openid (默认)
 */
export function getCode(fullPath, scope) {
  scope = scope == 'snsapi_userinfo' ? 'snsapi_userinfo' : 'snsapi_base'
  const href = buildUrl(fullPath || window.location.href)
  window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${process.env.VUE_APP_ID}&redirect_uri=${encodeURIComponent(href)}&response_type=code&scope=${scope}&state=${scope}#wechat_redirect&forcePopup=true&forceSnapShot=true`
}

/**
 * 微信浏览器
 * @returns bool
 */
export function isWechatBrowser() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  if (userAgent.match(/MicroMessenger/i) == 'micromessenger') {
    return true
  }
  return false
}

/**
 * 支付宝浏览器
 * @returns bool
 */
 export function isAlipayBrowser() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  if (userAgent.match(/Alipay/i) == 'alipay') {
    return true
  }
  return false
}

/**
 * ios/mac系统
 * @returns bool
 */
export function isIos() {
  return navigator.userAgent.indexOf('iPhone') > -1 || navigator.userAgent.indexOf('Mac OS') > -1
}

/**
 * 微信或支付宝浏览器
 * @returns bool
 */
export function isWechatOrAlipayBrowser() {
  if(isWechatBrowser() || isAlipayBrowser()) {
    return true
  }
  // 检查是否在微信或支付宝浏览器中
  return false;
}

/**
 * PC适配处理
 * @returns void
 */
export function pcNextHanlde(to, from, next) {
  if(isWechatBrowser()){
    return
  }
  // 生成唯一码
  // const guestId = localStorage.getItem('guest_id') || globalGuid()
  //localStorage.setItem('guest_id', guestId)
  let pcBoolean = localStorage.getItem('pcBoolean')
  if(!pcBoolean && globalGetQueryParam('pc')) {
    pcBoolean = true
    localStorage.setItem('pcBoolean', pcBoolean)
  }

  // window.top === window 在iframe内  或  innerWidth > innerHeight横屏（电脑） 或  没有pc参数不跳转pc适配页面
  if (window.top === window && window.innerWidth > window.innerHeight &&  !pcBoolean) {
      window.location.href = `pc.html?path=${to.fullPath}`
  }
}

/**
 * 主站引流跳转。
 */
export function mainWebsite(to, from, next, hasRedirected = false) {
  // 开机广告页
  if(to.path === '/adPage' && !isWechatMini() && to.query.access === ACCESS_ZST) {
    window.location.href = `${process.env.VUE_APP_SZZS_URL}${to.fullPath}`
    hasRedirected = true
  }
  return hasRedirected
}

/**
 * 微信小程序。
 */
export function isWechatMini() {
  const userAgent = window.navigator.userAgent.toLowerCase()
  if (userAgent.match(/MiniProgram/i) == 'miniProgram') {
    return true
  }
  return false
}
