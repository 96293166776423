const ToolsRouter = [
    {
        path: '/tools/goods-watermark',
        name: 'toolsGoodsWatermark',
        component: () => import(/* webpackChunkName: "about" */ '../views/tools/goodsWatermark'),
        meta: {
          title: '商品详情水印',
          keepAlive: false,
          
        }
    },
    {
      path: '/tools/chatgpt-verify',
      name: 'toolsChatgptVerify',
      component: () => import(/* webpackChunkName: "about" */ '../views/tools/chatgptVerify'),
      meta: {
        title: 'chatgpt白名单验证',
        keepAlive: false
      }
    },

]

export default ToolsRouter