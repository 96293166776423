import axios from 'axios'
import { Toast } from 'vant'
import store from '../store/index'
import router from '../router'
import { setStore } from '@/utils/setStore'
import { isWechatOrAlipayBrowser } from './wechatJsSdk'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    // 'X-Requested-With': 'XMLHttpRequest',
    'content-type': 'application/x-www-form-urlencoded'
  },
  timeout: 15000
})

// request interceptor // 请求拦截器
service.interceptors.request.use(
  config => {
    // Toast.loading({
    //   mask: false,
    //   duration: 0, // 一直存在
    //   forbidClick: true, // 禁止点击
    //   message: '加载中...'
    // })
    const token = store.state.token
    token && (typeof token !='boolean') && (config.headers.Authorization = token)

    return config
  },
  error => {
    Toast.clear()
    return Promise.reject(error.response.data) // 返回接口返回的错误信息
  }
)

// 响应拦截器
service.interceptors.response.use(res => {
  // 响应成功
  Toast.clear()
  if (res.data.code != 401) { // token 过期
    return res.data
  }

  // 响应失败
  if(res.data.code == 401){
    if(isWechatOrAlipayBrowser()){

        Toast('请登录')
        setStore('token', '')
        setStore('userInfo', {})
        store.commit('setCurrPageUrl', router.currentRoute.fullPath)
        location.reload()// 为了重新实例化 vue-router 对象，避免 bug

    }
  }


  // router.replace({
  //   name:"/",
  //   query: {redirect: router.currentRoute.fullPath}
  // })
  // router.push('/')
}, err => {

  if(err.response.status == 401){
    if(isWechatOrAlipayBrowser()){

        Toast('请登录')
        setStore('token', '')
        setStore('userInfo', {})
        store.commit('setCurrPageUrl', router.currentRoute.fullPath)
        location.reload()// 为了重新实例化 vue-router 对象，避免 bug

    }else{
        Toast('请求错误，请稍后再试')
    }
  }
  Toast.clear()
  return Promise.reject(err.response.data) // 返回接口返回的错误信息
})

export default service
