import request from '../utils/request'
import QS from 'qs'

export function getListData(data) {
  return request({
    url: '/api/live-room',
    method: 'get',
    params: data
  })
}
/**
 * @docs 获取共享图片
 * @param {room_id} 房间id
 */
export function getShareImg(data) {
  return request({
    url: '/api/live-room/share-image',
    method: 'get',
    params: data
  })
}
export function getMaterialData(data) {
  return request({
    url: '/api/material',
    method: 'get',
    params: data
  })
}
export function zhiboListgGift() {
  return request({
    url: '/api/live-room/gift',
    method: 'get'
  })
}
export function zhoboListDes(id, data) {
  return request({
    url: `/api/live-room/${id}`,
    method: 'get',
    params: data
  })
}
export function zhoboDesLike(id) {
  return request({
    url: `/api/live-room/${id}/like`,
    method: 'post'
  })
}
export function loginZhiBo() {
  return request({
    url: '/api/liveroom/getliveroomtoken',
    method: 'post'
  })
}

export function getWechat(data) { // 订阅获取消息
  return request({
    url: '/api/wechat/config',
    method: 'post',
    data: QS.stringify(data)
  })
}

export function messageSubscribe(data) { // 订阅获取消息
  return request({
    url: '/api/wechat/message-subscribe',
    method: 'post',
    data: QS.stringify(data)
  })
}

export function redEnvelopePay(id, data) { // 直播间发红包
  return request({
    url: `/api/live-room/${id}/redEnvelopePay`,
    method: 'post',
    data: QS.stringify(data)
  })
}
export function takeRedEnvelope(data) { // 直播间领红包
  return request({
    url: '/api/live-room/takeRedEnvelope',
    method: 'post',
    data: QS.stringify(data)
  })
}
export function getLiveRoomId(data) { // 直播间id
  return request({
    url: '/api/live-room/getLiveRoomIdByRedEnvelopePaymentNumber',
    method: 'post',
    data: QS.stringify(data)
  })
}
export function giftPay(id, data) { // 礼物支付
  return request({
    url: `/api/live-room/${id}/gift-pay`,
    method: 'post',
    data: QS.stringify(data)
  })
}
export function joinOrder(data) { // 生成直播间支付订单
  return request({
    url: `/api/live-room/join-order`,
    method: 'post',
    data: QS.stringify(data)
  })
}
