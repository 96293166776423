import store from '../store'
const FormBodyRouter = [
    {
        path: '/formBillApply',
        name: 'formBillApply',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/billApply'),
        meta: {
          title: '供应免押账期（一年）',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/billapplyReplenish',
        name: 'billapplyReplenish',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/billapplyReplenish'),
        meta: {
          title: '供应免押账期（补充）',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/applyForEdit',
        name: 'applyForEdit',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/applyForEdit'),
        meta: {
          title: '《广东名优企业品牌》提名资格填报',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/dealerBillApply',
        name: 'dealerBillApply',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/dealer/billApply'),
        meta: {
          title: '经销商免期账单',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/applyForCheck',
        name: 'applyForCheck',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/applyForCheck'),
        meta: {
          title: '《广东名优企业品牌》提名资格填报',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/applyFor',
        name: 'applyFor',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/applyFor'),
        meta: {
          title: '《广东名优企业品牌》提名资格填报',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/brandAuthorization',
        name: 'brandAuthorization',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/brandAuthorization'),
        meta: {
          title: '“古镇灯饰”商标申请授权',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/digitalClub',
        name: 'digitalClub',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/digitalClub'),
        meta: {
          title: '数字俱乐部会员',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/ipSuperImage',
        name: 'ipSuperImage',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/ipSuperImage'),
        meta: {
          title: '国际超级IP',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/ipSuper',
        name: 'ipSuper',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/ipSuper'),
        meta: {
          title: '国际超级IP',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
       {
        path: '/universe',
        name: 'universe',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/universe'),
        meta: {
          title: '申请数字4宝制作',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
       {
        path: '/domainKey',
        name: 'domainKey',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/domainKey'),
        meta: {
          title: '线上全域交钥匙运营合作',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/digitalPerson',
        name: 'digitalPerson',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/digitalPerson'),
        meta: {
          title: '申请数字人制作',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/photocontest',
        name: 'photocontest',
        component: () => import('@/views/user/photocontest/photocontest'),
        meta: {
          keepAlive: false,
          isBack: false,
          title: '精品中山动漫摄影大赛报名表',
          requireLogin: false
        }
      },
      {
        path: '/photoSignUp',
        name: 'photoSignUp',
        component: () => import('@/views/user/photocontest/signUp'),
        meta: {
          keepAlive: false,
          isBack: false,
          title: '精品中山动漫摄影大赛报名表',
          requireLogin: false
        }
      },
      {
        path: '/CityHouseSignUp',
        name: 'CityHouseSignUp',
        component: () => import('@/views/user/CityHouse/CityHouseSignUp'),
        meta: {
          keepAlive: false,
          isBack: false,
          title: '中山城市客厅预约',
          requireLogin: true
        }
      },
      {
        path: '/CityHouse',
        name: 'CityHouse',
        component: () => import('@/views/user/CityHouse'),
        meta: {
          keepAlive: false,
          isBack: false,
          title: '中山市城市客厅申请',
          requireLogin: true
        }
      },
      {
        path: '/enterprise',
        name: 'enterprise',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/enterprise'),
        meta: {
          title: '申请对接央国企',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/scenery',
        name: 'scenery',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/scenery'),
        meta: {
          title: '申请对接景区',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/travel',
        name: 'travel',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/travel'),
        meta: {
          title: '申请对接文旅',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/joinTerrace',
        name: 'joinTerrace',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/joinTerrace'),
        meta: {
          title: '申请进驻平台',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/joinSaloon',
        name: 'joinSaloon',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/joinSaloon'),
        meta: {
          title: '申请进驻客厅',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/digitalFactory',
        name: 'digitalFactory',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/digitalFactory'),
        meta: {
          title: '申请数字化工厂',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/newPalace',
        name: 'newPalace',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/newPalace'),
        meta: {
          title: '投上新了故宫',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/bestGoods',
        name: 'bestGoods',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/bestGoods'),
        meta: {
          title: '精品中山',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/bestGoodsGift',
        name: 'bestGoodsGift',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/bestGoodsGift'),
        meta: {
          title: '中山手信',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/jpMember',
        name: 'jpMember',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/jpMember'),
        meta: {
          title: '申请精品会员',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/guruGoods',
        name: 'guruGoods',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/guruGoods'),
        meta: {
          title: '达人申请样品',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/manufactor',
        name: 'manufactor',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/manufactor'),
        meta: {
          title: '厂家接口',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/discountSharing',
        name: 'discountSharing',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/discountSharing'),
        meta: {
          title: '商家优惠共享',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/subscribeSalon',
        name: 'subscribeSalon',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/subscribeSalon'),
        meta: {
          title: '预约沙龙',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/acquireModel',
        name: 'acquireModel',
        component: () => import(/* webpackChunkName: "about" */ '../views/goods/acquireModel'),
        meta: {
          title: '领取样品',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/tiktokSample',
        name: 'tiktokSample',
        component: () => import(/* webpackChunkName: "about" */ '../views/goods/tiktokSample'),
        meta: {
          title: '上抖音橱窗',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/registerCode',
        name: 'registerCode',
        component: () => import('../views/user/registerCode'),
        meta: {
          keepAlive: false,
          isBack: false,
          title: '快码推广',
          requireLogin: true
        }
      },
      {
        path: '/polyCode',
        name: 'polyCode',
        component: () => import('../views/user/polyCode'),
        meta: {
          keepAlive: false,
          isBack: false,
          title: store.state.shopinfo.poly_switch == 1 ? '快码' : '快码申请',
          requireLogin: true
        }
      },
      {
        path: '/orderRecord',
        name: 'orderRecord',
        component: () => import('../views/user/orderRecord'),
        meta: {
          title: '收款码收款记录',
          requireLogin: true
        }
      },
      {
        path: '/form-best-car',
        name: 'formBestCar',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/bestCar'),
        meta: {
          title: '精品二手',
          requireLogin: true
        }
      },
      {
        path: '/industryStudy',
        name: 'industryStudy',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/industryStudy'),
        meta: {
          title: '产业研学',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/industryStudyInformation',
        name: 'industryStudyInformation',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/industryStudyInformation'),
        meta: {
          title: '产业研学资料',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/industryStudyHk',
        name: 'industryStudyHk',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/industryStudyHk'),
        meta: {
          title: '产业研学',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/industryStudyBj',
        name: 'industryStudyBj',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/industryStudyBj'),
        meta: {
          title: '北京研学',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/classigy',
        name: 'classigy',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/classigy'),
        meta: {
          title: '需申请研学',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
      {
        path: '/kitchen',
        name: 'kitchen',
        component: () => import(/* webpackChunkName: "about" */ '../views/user/form/kitchen'),
        meta: {
          title: '会移动的厨房',
          keepAlive: false,
          isBack: false,
          requireLogin: true
        }
      },
]

export default FormBodyRouter
