const HaiTuanRouter = [
      {
        path: '/haiTuan',
        name: 'haiTuan',
        component: () => import(/* webpackChunkName: "about" */ '../views/haituan/HomeHaiTuan.vue'),
        meta: {
          title: '海团购'
        }
      },
      {
        path: '/haituan-metaverse',
        name: 'haituanMetaverse',
        component: () => import(/* webpackChunkName: "about" */ '../views/haituan/metaverse.vue'),
        meta: {
          title: '各国元宇宙',
        }
      },
]

export default HaiTuanRouter