<template>
  <div id="app" :data-theme="appTheme">
    <keep-alive
      :include="[
        'ZhiBoList',
        '/',
        'FactoryList',
        'Classify',
        'HomeSec',
        'ActivityTheme',
        'DigitalMarketing',
      ]"
    >
      <router-view class="Router" />
    </keep-alive>
    <!--    <router-view class="Router" v-if="!$route.meta.keepAlive"></router-view>-->
    <!--    <tabBar v-if="($route.path==='/cart'||$route.path==='/' || $route.path==='/service' || $route.path==='/user')"/>-->
    <tabBar v-if="rootshow" />
    <div class="longin-dialog">
      <van-dialog
        v-model="longDialogShow"
        radius="10"
        width="76%"
        confirm-button-text="微信一键登录"
        confirm-button-color="#000"
        @confirm="confirm"
      >
        <div class="logo">
          <van-image
            width="100"
            round
            height="100"
            :src="require('/@themes/login.png')"
          />
        </div>
        <van-icon class="cross" size="4.8vw" name="cross" @click="crossDialog" />
        <div class="title">提示</div>
        <div class="mark">请进行微信用户一键登录以继续</div>
        <div class="mark m-b-40">使用本系统</div>
      </van-dialog>

      <van-dialog
        v-model="longDialogShowPC"
        radius="10"
        width="76%"
        confirm-button-text="立即登录"
        confirm-button-color="#000"
        @confirm="confirmPC"
      >
        <div class="logo">
          <van-image
            width="100"
            round
            height="100"
            :src="require('/@themes/login.png')"
          />
        </div>

        <van-icon
          class="cross"
          size="4.8vw"
          name="cross"
          @click="crossDialogPC"
        />
        <van-field v-model="pcPassword" type="password" label="访问密码" />

      </van-dialog>
    </div>
  </div>
</template>

<script>
// 全局守卫
// import router from './router'
import { systemConfig, loginAsGuest,getGuestId } from "@/api/info";
import { getCode } from "@/utils/wechatJsSdk.js";
import { buildUrl } from "@/utils/getParam.js";
import VConsole from "vconsole";
import tabBar from "./components/tabBar";
const wx = require('jweixin-module')

export default {
  components: {
    tabBar,
  },
  data() {
    return {
      longDialogShowPC: this.$store.state.longDialogShowPC,
      longDialogShow: this.$store.state.longDialogShow,
      appTheme: this.$store.state.appTheme,
      login: 1, // 0显示登陆 1隐藏
      pcPassword:""
    };
  },
  computed: {
    Show() {
      return this.$store.state.longDialogShow;
    },
    ShowPC() {
      return this.$store.state.longDialogShowPC;
    },
    tabbarShow() {
      return this.$store.state.tabbarFalg;
    },
    rootshow() {
      const routerList = [
        "/cart",
        "/",
        "/applyForCheck",
        "/oneYuan",
        "/xieYiList",
        "/searchList",
        "/classifyList",
        "/goodList",
        "/formBillApply",
        "/user",
        "/order",
        "/goodList",
        "/goodClassify",
        "/materialList",
        "/factoryList",
        "/roomPassword",
        "/CityHouse",
      ];
      return routerList.includes(this.$route.path);
    },
    path() {
      return this.$route.path;
    },
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    Show(to, from) {
      this.longDialogShow = to;
    },
    ShowPC(to, from) {
      this.longDialogShowPC = to;
    },
    longDialogShow(to, from) {
      this.$setvuex("longDialogShow", to);
    },
    longDialogShowPC(to, from) {
      this.$setvuex("longDialogShowPC", to);
    },
    $route(to, from) {
      // 判断是否分享
      // oneWechatShare 部分页面多次调用问题
      // wechatShare 是否分享
      if (!to.meta.wechatShare) {
        if(to.meta.oneWechatShare) return
        this.getShareInfo();
      }else{
        console.log('禁止微信分享')
        this.$hideShare()
      }
      // 判断是否显示tabbar
      const tabbarShow = this.$store.state.token == "" ? false : true;
      this.$store.commit("setTabbarShow", tabbarShow);
    },
  },
  created() {
    if (this.$store.state.vConsoleShow === true) {
      new VConsole();
    }
    this.getSystemConfig();
  },
  beforeDestroy() {
    this.$store.commit("setWechatSignUrl", "");
  },
  methods: {
    getSystemConfig() {
      // 获取主题色
      systemConfig().then((res) => {
        if (res.code == 200) {
          this.$setvuex("appTheme", res.data.theme);
          this.$setvuex("systemConfig", res.data);
        }
      });
    },
    crossDialog() {
      if (window.location.pathname != "/") {
        // this.$navigateBack()
      }
      this.longDialogShow = false;
    },
    confirm() {
      this.$setvuex("token", "");
      this.$setvuex("userInfo", {});
      getCode(
        buildUrl(window.location.origin + this.$store.state.toPageUrl),
        "snsapi_userinfo"
      );
    },

    confirmPC() {
      this.$setvuex("token", "");
      this.$setvuex("userInfo", {});
      if(!this.pcPassword){
        this.$dialog.alert({
          message: '请输入密码',
          confirmButtonText: '知道了'
        }).then(() => {
          this.longDialogShowPC = true;
        });
        return;
      }
      getGuestId({
        password:this.pcPassword
      })
          .then((res1) => {
            if (res1.code == 200) {
              // localStorage.setItem('guest_id', res1.data)
              //用游客标识获取 用户登录token
              loginAsGuest({
                guestId: res1.data,
              }).then((res) => {
                if (res.code == 200) {
                  this.$setvuex("token", res.data.token); // 保存token
                  this.$setvuex("userInfo", res.data.user);
                  this.longDialogShowPC = false;

                  this.$toast({
                    message: "登录成功",
                    duration: 3000,
                    onClose: () => {
                        location.reload();
                      }
                  });
                }
              });
            }else{
              this.$dialog.alert({
                message: '登录失败',
                confirmButtonText: '知道了'
              }).then(() => {
                this.longDialogShowPC = true;
              });

            }
        });
    },
    crossDialogPC() {
      this.longDialogShowPC = false;
    },
    getShareInfo() {
      // const urlPath = window.location.href;
      // console.log("path", urlPath);
      this.$wexinShare({
        title: this.$route.meta.title, // 分享时的标题
        desc: this.$route.meta.desc ? this.$route.meta.desc : "", // 分享时的描述
        // imgUrl: '',分享时的图片
      }); // 引用微信分享
    },
  },
};
</script>

<style lang="scss">
@import "@/style/flex.scss"; //、引入flex布局样式
@import "@/style/common.scss"; //引入公共样式
// @import "/@themes/css/color.scss";   //引入主题样式
@import "@/style/index.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #282828;
  font-size: 28px;
}

body {
  background: #f5f5f3;
}

.null-box {
  height: 120px;
}

/* 文本溢出隐藏 */
.uni-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.justify-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-justifying {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.longin-dialog .van-dialog__confirm {
  background-image: linear-gradient(90deg, #fbd916, #68c350);
  //导航背景
  height: 86px;
  color: #fff;
  width: 200px !important;
  border-radius: 48px;
  bottom: 0 20px;
}

.longin-dialog .van-dialog {
  padding: 20px;
  border-radius: 16px;
  overflow: visible;
}
.longin-dialog .van-dialog__footer {
  width: 86%;
  margin: 30px auto;
}
.longin-dialog .van-button__text {
  color: #fff;
  font-weight: normal;

  // font-weight: bold;
}

.longin-dialog .van-dialog__content {
  padding-top: 136px;
  position: relative;
}

.longin-dialog .van-image {
  padding: 30px;
  background-image: linear-gradient(90deg, #fbd916, #68c350);
}

.longin-dialog .logo {
  position: absolute;
  padding: 16px;
  height: 260px;
  width: 260px;
  top: -150px;
  left: calc(50% - 146px);
  background-color: #fff;
  border-radius: 50%;
}
.longin-dialog .cross {
  position: absolute;
  right: 0;
  top: 0;
}
.longin-dialog .title {
  text-align: center;
  font-size: 38px;
  letter-spacing: 2px;
  line-height: 93px;
  color: #383838;
  font-weight: 600;
}
.longin-dialog .mark {
  font-size: 32px;
  line-height: 42px;
  color: #7a7a7a;
  font-weight: 400;
  text-align: center;
}

.m-b-40 {
  margin-bottom: 40px;
}
.top-icon-box {
  position: fixed;
  right: 30px;
  bottom: 200px;
  z-index: 88;
  width: 100px;
  width: 80px;
  height: 80px;
  font-weight: bold;
  box-shadow: 0 0 20px #bbbbbb;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-self: center;
  justify-content: center;
  background-color: $bgAssist;
  color: $textAssist;

  // .top-icon {

  //   width: 100%;
  //   height: auto;
  //   display: block;
  // }
}
</style>
