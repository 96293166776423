import request from '../utils/request'
import QS from 'qs'

export function getLogin(data) {
  return request({
    url: '/api/wechat/mp-login',
    method: 'get',
    params: data
  })
}
export function getUserInfo() {
  return request({
    url: '/api/user',
    method: 'get'
  })
}
export function systemConfig() {
  return request({
    url: '/api/basic/system-config',
    method: 'get'
  })
}
export function platformMember(data) {
  return request({
    url: '/api/user/platform-member',
    method: 'post',
    data: QS.stringify(data)
  })
}
// /api/user/sign-in
export function signInSubmit() {
  return request({
    url: '/api/user/sign-in',
    method: 'post'
  })
}
// /api/user/sign-in
export function getSignIn(data) {
  return request({
    url: '/api/user/sign-in',
    method: 'get',
    params: data
  })
}
export function getmemberinfo(data) {
  return request({
    url: '/api/member/home',
    method: 'get',
    params: data
  })
}
// 购买会员 POST
export function getmemberorder(id, data) {
  return request({
    url: `/api/member/order/${id}`,
    method: 'post',
    data: QS.stringify(data)
  })
}

export function getsharedomestic(data) {
  return request({
    url: '/api/share/domestic',
    method: 'get',
    params: data
  })
}
// 购买会员 POST
export function getsharewithdrawal(data) {
  return request({
    url: `/api/share/withdrawal`,
    method: 'post',
    data: QS.stringify(data)
  })
}
// 分佣-佣金记录
// GET /api/share/profit

export function getshareprofit(data) {
  return request({
    url: '/api/share/profit',
    method: 'get',
    params: data
  })
}
export function getintegral_core(data) {
  return request({
    url: '/api/integral/shop/integral-core',
    method: 'get',
    params: data
  })
}
// 获取积分规则
// GET /api/integral/shop/integral-rules

export function integral_rules(data) {
  return request({
    url: '/api/integral/shop/integral-rules',
    method: 'get',
    params: data
  })
}
// 积分记录
// GET /api/user/integral-log
export function iintegralLog(data) {
  return request({
    url: '/api/user/integral-log',
    method: 'get',
    params: data
  })
}

//游客登录注册
export function loginAsGuest(data) {
  return request({
    url: '/api/guest/pc-login',
    method: 'post',
    data: QS.stringify(data)
  })
}

//获取加密id
export function getGuestId(data) {
  return request({
    url: '/api/guest/get-id',
    method: 'get',
    params: data
  })
}
