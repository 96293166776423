import store from '@/store'

/**
 * vuex 变量简单修改修改
 *
 * @param {string} name 要修改的vuex 名称
 * @param {any} value 传入的值
 * @return null
 */
export function setStore(name, value) {
  store.commit('$uStore', {
    name, value
  })
}

