var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app","data-theme":_vm.appTheme}},[_c('keep-alive',{attrs:{"include":[
      'ZhiBoList',
      '/',
      'FactoryList',
      'Classify',
      'HomeSec',
      'ActivityTheme',
      'DigitalMarketing',
    ]}},[_c('router-view',{staticClass:"Router"})],1),(_vm.rootshow)?_c('tabBar'):_vm._e(),_c('div',{staticClass:"longin-dialog"},[_c('van-dialog',{attrs:{"radius":"10","width":"76%","confirm-button-text":"微信一键登录","confirm-button-color":"#000"},on:{"confirm":_vm.confirm},model:{value:(_vm.longDialogShow),callback:function ($$v) {_vm.longDialogShow=$$v},expression:"longDialogShow"}},[_c('div',{staticClass:"logo"},[_c('van-image',{attrs:{"width":"100","round":"","height":"100","src":require('/@themes/login.png')}})],1),_c('van-icon',{staticClass:"cross",attrs:{"size":"4.8vw","name":"cross"},on:{"click":_vm.crossDialog}}),_c('div',{staticClass:"title"},[_vm._v("提示")]),_c('div',{staticClass:"mark"},[_vm._v("请进行微信用户一键登录以继续")]),_c('div',{staticClass:"mark m-b-40"},[_vm._v("使用本系统")])],1),_c('van-dialog',{attrs:{"radius":"10","width":"76%","confirm-button-text":"立即登录","confirm-button-color":"#000"},on:{"confirm":_vm.confirmPC},model:{value:(_vm.longDialogShowPC),callback:function ($$v) {_vm.longDialogShowPC=$$v},expression:"longDialogShowPC"}},[_c('div',{staticClass:"logo"},[_c('van-image',{attrs:{"width":"100","round":"","height":"100","src":require('/@themes/login.png')}})],1),_c('van-icon',{staticClass:"cross",attrs:{"size":"4.8vw","name":"cross"},on:{"click":_vm.crossDialogPC}}),_c('van-field',{attrs:{"type":"password","label":"访问密码"},model:{value:(_vm.pcPassword),callback:function ($$v) {_vm.pcPassword=$$v},expression:"pcPassword"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }